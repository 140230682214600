import { toast } from "react-toastify";
import { fetchRechargeSuccess, fetchRechargeFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { RECHARGE_API } from "../constants/constants";
import { setSuccessMessage } from "../toastMessage/action";
import { fetchBalance } from "../customer/balance/thunk";
import { fetchCustomerSummary } from "../customer/simulate/thunk";

export const recharge = (jsonObj, setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${RECHARGE_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCustomerSummary(jsonObj?.customerID, setLoading, "View"))
            dispatch(fetchRechargeSuccess(response))
            dispatch(setSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchRechargeFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}