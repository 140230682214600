import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import { LabelText } from "../Utils/Input";
import formSchema from "../Utils/schema.json";
import { Formik } from "formik";
import { validationSchemaForUpdateSimulate } from "./constants/validationSchema";
import { RenderField } from "../Utils/renderFields";
import { RenderErrorMessage } from "../Utils/renderErrorMessages";
import CommonButton from "../Utils/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import { customerIdData } from "./constants/constantFunctionsView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Loader } from "../Utils/Loader";
import { optionsForSubscriptionId, uomOptions } from "./constants/SessionFunctions";
import { fetchEventTypes } from "../../slices/sessions/eventType/thunk";
import { ToastContainer } from "react-toastify";
import { fetchDetailedBalance } from "../../slices/customer/detailedBalance/thunk";
import { updateSessionApiCall } from "./constants/UpdateSessionjson";

export const UpdateSimulate = ({ updateSession, setUpdateSession, sessionInfo, terminateSessions, setTerminateSessions, setButtonInitiateSession }) => {
    const navigate = useNavigate();
    const customerId = customerIdData();
    const dispatch = useDispatch();
    const location = useLocation();
    const [modalForSimulate, setModalForSimulate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eventTypeData, setEventTypeData] = useState();
    const [uomData, setUomData] = useState();
    let initialValues = {
        sessionId: '',
        sequence: '',
        sessionTime: null,
        requestedUnits: '',
        usedUnits: '',
    };
    initialValues.sessionId = sessionInfo.sessionId
    initialValues.sequence = sessionInfo.sequenceNumber
    const isView = location.pathname.includes('view-customer')
    useEffect(() => {
        if (customerId) {
            dispatch(fetchDetailedBalance(customerId, setLoading, false, navigate, isView, setModalForSimulate,));
        }
    }, [customerId]);

    let detailedBalanceData = useSelector((state) => state.DetailedBalance.detailedBalance);
    let eventType = useSelector((state) => state.EventType.eventType);
    const optionsOfSubscriptions = optionsForSubscriptionId(detailedBalanceData?.resources);
    const simulateOptions = [
        {
            name: "subscriptionId",
            value: "UpdateSimulate",
            options: optionsOfSubscriptions,
        },
        eventTypeData,
        uomData,
    ];

    return (
        <>
            {updateSession && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemaForUpdateSimulate(terminateSessions)}
                    onSubmit={(value) => {
                        updateSessionApiCall(value, dispatch, setLoading, customerId, setUpdateSession, isView, sessionInfo, terminateSessions, setButtonInitiateSession);
                    }}
                >
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        setFieldTouched,
                    }) => {
                        useEffect(() => {
                            if (values?.subscriptionId?.value) {
                                dispatch(fetchEventTypes(values?.subscriptionId?.value, setLoading, setEventTypeData));
                            }
                            setFieldValue("usageEventType", "");
                        }, [values?.subscriptionId?.value]);
                        useEffect(() => {
                            if (values?.usageEventType?.value) {
                                let UOMOptions = uomOptions(eventType, values?.usageEventType?.value);
                                const extraObject = {
                                    name: "uom",
                                    value: "UpdateSimulate",
                                    options: UOMOptions,
                                };
                                setUomData(extraObject);
                            }
                            setFieldValue("uom", "");
                        }, [values?.usageEventType?.value]);
                        return (
                            <Form id="csms" className="needs-validation mt-n3" onSubmit={handleSubmit}>
                                <div>
                                    <ToastContainer position="top-center" />
                                    {loading && <Loader />}
                                    {formSchema.map((section, sectionIndex) =>
                                        section.title === "UpdateSimulate" &&
                                        section.sections &&
                                        section.sections.map((subSection, subSectionIndex) =>
                                            subSection?.sectionTitle ? (
                                                <Card key={subSectionIndex}>
                                                    <CardHeader className="align-items-center d-flex">
                                                        <h4 className="card-title mb-0 flex-grow-1">
                                                            {!terminateSessions ? subSection?.sectionTitle : 'Terminate Session'}                                                        </h4>
                                  
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row key={sectionIndex}>
                                                            <Col lg={12}>
                                                                <Row className="gy-4">
                                                                    {subSection.fields
                                                                        .filter((field) => {
                                                                            if (terminateSessions && (field.name == "sequence" || field.name == "requestedUnits")) {
                                                                                return false;
                                                                            }
                                                                            return true;
                                                                        })
                                                                        .map((field, fieldIndex) => (
                                                                            <Col
                                                                                md={4}
                                                                                lg={2}
                                                                                key={`${subSectionIndex}-one-${fieldIndex}`}
                                                                            >
                                                                                <LabelText
                                                                                    htmlFor={field.name}
                                                                                    className={field.required ? "text-danger" : ""}
                                                                                    displayText={field.label}
                                                                                    important={field.required ? "*" : ""}
                                                                                />
                                                                                <RenderField
                                                                                    field={field}
                                                                                    handleChange={handleChange}
                                                                                    handleBlur={handleBlur}
                                                                                    values={values}
                                                                                    touched={touched}
                                                                                    errors={errors}
                                                                                    setFieldTouched={setFieldTouched}
                                                                                    setFieldValue={setFieldValue}
                                                                                    options={simulateOptions}
                                                                                />
                                                                                <RenderErrorMessage
                                                                                    field={field}
                                                                                    touched={touched}
                                                                                    errors={errors}
                                                                                />
                                                                            </Col>

                                                                        ))}
                                                                    <Row lg={6} className=" justify-content-end mb-2 ms-2">
                                                                        <div className="live-preview">
                                                                            <div className="d-flex justify-content-end gap-2 mt-4 ">
                                                                                <CommonButton
                                                                                    outline={true}
                                                                                    className={"cancel-button-background"}
                                                                                    color={"main-color"}
                                                                                    type={"reset"}
                                                                                    buttonAction={() => {
                                                                                        setUpdateSession(false);
                                                                                        setButtonInitiateSession(true);
                                                                                    }}
                                                                                    buttonText={"Cancel"}
                                                                                />
                                                                                <CommonButton
                                                                                    color={"main-color"}
                                                                                    type={"submit"}
                                                                                    buttonText={"Confirm"}
                                                                                    className={'py-1'}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Row>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            ) : null
                                        )
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};
