import { toast } from "react-toastify"
import { UPDATE_SESSION } from "../../constants/constants"
import { fetchDetailedBalance } from "../../customer/detailedBalance/thunk"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchAllSessions } from "../getAllSessions/thunk"
import { fetchUpdateSessionFailure, fetchUpdateSessionSuccess } from "./reducer"
import { fetchCustomerSummary } from "../../customer/simulate/thunk"

export const updateSession = (jsonObj, setLoading,customerId,subscriptionId,setUpdateSession,setButtonInitiateSession) => async (dispatch) => {
    await determineBaseUrlAndPerformApiCall(`${UPDATE_SESSION}`, 'POST',jsonObj).then((response) => {
        setLoading(true)
        if (response?.error) {
            setLoading(false)
            dispatch(fetchUpdateSessionFailure(response.error.message))
            toast.error(`${response.error.message}`)
        }
        if (!response?.error) {
            setLoading(false)
            dispatch(fetchDetailedBalance(subscriptionId, setLoading,false));
            dispatch(fetchAllSessions(customerId,subscriptionId))
            dispatch(fetchUpdateSessionSuccess(response))
            dispatch(fetchCustomerSummary(customerId, setLoading, "View"));
            setButtonInitiateSession(true)
            toast.success('Session Updated Successfully')
            setUpdateSession(false)
        }
    })
}