import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import { LabelText } from "../Utils/Input";
import formSchema from "../Utils/schema.json";
import { Formik } from "formik";
import { validationSchemaForTelcoSimulate } from "./constants/validationSchema";
import { RenderField } from "../Utils/renderFields";
import { RenderErrorMessage } from "../Utils/renderErrorMessages";
import CommonButton from "../Utils/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import { customerIdData } from "./constants/constantFunctionsView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Loader } from "../Utils/Loader";
import { initiateSessionApiCall, optionsForSubscriptionId, uomOptions } from "./constants/SessionFunctions";
import { fetchEventTypes } from "../../slices/sessions/eventType/thunk";
import { ToastContainer } from "react-toastify";
import { fetchDetailedBalance } from "../../slices/customer/detailedBalance/thunk";

export const ActiveSimulate = ({ showForm, setShowForm }) => {
    let initialValues = {
        subscriptionId: '',
        usageEventType: '',
        sessionId: '',
        sessionTime: null,
        requestedUnits: '',
        uom: ''
    }
    const navigate = useNavigate();
    const customerId = customerIdData();
    const dispatch = useDispatch();
    const location = useLocation();
    const [modalForSimulate, setModalForSimulate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eventTypeData, setEventTypeData] = useState();
    const [uomData, setUomData] = useState();
    const fullName = location?.state?.Name;
    const isView = location.pathname.includes('view-customer') || location.pathname.includes('simulate')
    // useEffect(() => {
    //     if (customerId) {
    //         dispatch(fetchDetailedBalance(customerId, setLoading, false, navigate, isView, setModalForSimulate,));
    //     }
    // }, [customerId]);

    // let detailedBalanceData = useSelector((state) => state.DetailedBalance.detailedBalance);
    let eventType = useSelector((state) => state.EventType.eventType);
    const customerData = useSelector((state) => state.FetchCustomer.fetchCustomer)
    const optionsOfSubscriptions = optionsForSubscriptionId(customerData?.subscriptionList);
    const simulateOptions = [
        {
            name: "subscriptionId",
            value: "ActiveSimulate",
            options: optionsOfSubscriptions,
        },
        eventTypeData,
        uomData,
    ];

    return (
        <>
            {showForm && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemaForTelcoSimulate}
                    onSubmit={(value) => {
                        initiateSessionApiCall(value, dispatch, setLoading, navigate, customerId, fullName, setShowForm, isView);
                    }}
                >
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        setFieldTouched,
                    }) => {
                        useEffect(() => {
                            if (values?.subscriptionId?.value) {
                                dispatch(fetchEventTypes(values?.subscriptionId?.value, setLoading, setEventTypeData));
                            }
                            setFieldValue("usageEventType", "");
                        }, [values?.subscriptionId?.value]);

                        useEffect(() => {
                            if (values?.usageEventType?.value) {
                                let UOMOptions = uomOptions(eventType, values?.usageEventType?.value);
                                const extraObject = {
                                    name: "uom",
                                    value: "ActiveSimulate",
                                    options: UOMOptions,
                                };
                                setUomData(extraObject);
                            }
                            setFieldValue("uom", "");
                        }, [values?.usageEventType?.value]);

                        return (
                            <Form id="csms" className="needs-validation mt-n3" onSubmit={handleSubmit}>
                                <div>
                                    <ToastContainer position="top-center" />
                                    {loading && <Loader />}
                                    {formSchema.map((section, sectionIndex) =>
                                        section.title === "ActiveSimulate" &&
                                        section.sections &&
                                        section.sections.map((subSection, subSectionIndex) =>
                                            subSection?.sectionTitle ? (
                                                <Card key={subSectionIndex}>
                                                    <CardHeader className="align-items-center d-flex">
                                                        <h4 className="card-title mb-0 flex-grow-1">
                                                            {subSection?.sectionTitle}
                                                        </h4>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row key={sectionIndex}>
                                                            <Col lg={12}>
                                                                <Row className="gy-4">
                                                                    {subSection.fields
                                                                        .filter((field) => {
                                                                            if (field.name === "sessionId") {
                                                                                field.disable = !values.subscriptionId;
                                                                            }
                                                                            if (field.name === "usageEventType") {
                                                                                field.disable = !values.subscriptionId;
                                                                            }
                                                                            if (field.name === "sessionTime") {
                                                                                field.disable = !values.subscriptionId;
                                                                            }
                                                                            if (field.name === "requestedUnits") {
                                                                                field.disable = !values.subscriptionId;
                                                                            }
                                                                            if (field.name === "uom") {
                                                                                field.disable = !values.subscriptionId;
                                                                            }
                                                                            return true;
                                                                        })
                                                                        .map((field, fieldIndex) => (
                                                                            <Col
                                                                                xxl={
                                                                                    field.name === "subscriptionId" ||
                                                                                        field.name === "usageEventType"
                                                                                        ? 6
                                                                                        : 3
                                                                                }
                                                                                md={3}
                                                                                lg={3}
                                                                                key={`${subSectionIndex}-one-${fieldIndex}`}
                                                                            >
                                                                                <LabelText
                                                                                    htmlFor={field.name}
                                                                                    className={
                                                                                        field.required
                                                                                            ? "text-danger"
                                                                                            : ""
                                                                                    }
                                                                                    displayText={field.label}
                                                                                    important={field.required ? "*" : ""}
                                                                                />
                                                                                <RenderField
                                                                                    field={field}
                                                                                    handleChange={handleChange}
                                                                                    handleBlur={handleBlur}
                                                                                    values={values}
                                                                                    touched={touched}
                                                                                    errors={errors}
                                                                                    setFieldTouched={setFieldTouched}
                                                                                    setFieldValue={setFieldValue}
                                                                                    options={simulateOptions}
                                                                                />
                                                                                <RenderErrorMessage
                                                                                    field={field}
                                                                                    touched={touched}
                                                                                    errors={errors}
                                                                                />
                                                                            </Col>
                                                                        ))}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <div lg={12} className="d-flex justify-content-end align-item-center  gap-2 mt-3 ">
                                                            <CommonButton
                                                                outline={true}
                                                                className={"cancel-button-background"}
                                                                color={"main-color"}
                                                                type={"reset"}
                                                                buttonAction={() => setShowForm(false)}
                                                                buttonText={"Cancel"}
                                                            />
                                                            <CommonButton
                                                                color={"main-color"}
                                                                type={"submit"}
                                                                buttonText={"Confirm"}
                                                                className={'py-1'}
                                                                // disabled={loading}
                                                                disabled={
                                                                    !values.subscriptionId ||
                                                                    !values?.sessionTime ||
                                                                    !values?.sessionId ||
                                                                    !values?.requestedUnits ||
                                                                    !values?.uom ||
                                                                    !values?.usageEventType
                                                                }
                                                            />
                                                            {/* <Button
                                                            className="bg-transparent border-0 shadow-none py-1"
                                                            onClick={() => setShowForm(false)} // Hide the form
                                                        > */}
                                                            {/* <i className="ri-close-line fs-3 ms-2 orange-main-colour" onClick={() => setShowForm(false)} ></i> */}
                                                            {/* </Button> */}
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            ) : null
                                        )
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};
