import { toast } from "react-toastify"
import { UPDATE_OPERATOR_CONFIGURATION } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchUpdateConfigOperatorFailure, fetchUpdateConfigOperatorSucess } from "./reducer"

export const updateConfigOperator = (jsonObj, setLoading,navigate,isOperator) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${UPDATE_OPERATOR_CONFIGURATION}/${jsonObj.operatorId}`, 'PUT', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchUpdateConfigOperatorSucess(response))
            if(isOperator){
                navigate('/dashboard')
            }else{
                navigate('/operator-listing');
            }
        }
        if (response.error) {
            dispatch(fetchUpdateConfigOperatorFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    })
}