import { MaterialReactTable } from "material-react-table";
import { Button, Card, CardBody, Row } from "reactstrap";
import { CustomerListRowOptions } from "./CustomerListRowOptions";
import { CustomerPreview } from "./CustomerPreview";
import Simulate from "./Simulate";
import React, { useEffect, useState } from "react";
import CommonModal from "../Utils/CommonModal";
import { deletecustomer } from "../../slices/customer/deletecustomer/thunk";
import { MenuItem } from '@mui/material';
import { navigateToSubscribe } from "./constants/DocumentTableFunctions";
import { fetchDetailedBalance } from "../../slices/customer/detailedBalance/thunk";
import { navigateToCreateCustomer } from "./constants/ListingCustomerFunctions";
import { PasswordModal } from "./PasswordModal";
import { resetUpdatePasswordState } from "../../slices/customer/passwordUpdate/reducer";
import { useSelector } from "react-redux";

export const TableForTelco = ({
    getTable,
    searchColumns,
    searchData,
    orderPermission,
    customerPermission,
    navigate,
    dispatch,
    setRenderSimulate,
    setLoading,
    simulateOrPreview,
    index,
    setIndex,
    setActiveTab,
    fetchCustomerDetails,
    stationPermission,
    reservationPermission,
    getCustomerData,
    storeRow,
    setDefault,
    renderSimulate,
    pagination,
    customerData,
    activeTab,
    tabChange,
    setOpen,
    toggle,
    noCurrencyArray,
    currencyArray,
    fetchCustomerSummaryData,
    reservationDataArray,
    duration,
    subscriptionsArray,
    convertNumberOfDaysToMonthYear,
    formatDate,
    getDayDiff,
    current,
    errorMessage,
    errorIcon,
    setGetTable,
    toggleModal,
    setMessageForPopUp,
    setCustomerId,
    messageForPopUp,
    modal,
    customerId,
    TotalCustomers,
    searchExpanding
}) => {

    const [modalForSimulate, setModalForSimulate] = useState(false)
    const [passwordModal, setPasswordModal] = useState(false)
    const [passwordCustomer, setPasswordCustomer] = useState(null)
    const isView = location.pathname.includes('view-customer')

    const [dontShowCloseButton, setDontShowCloseButton] = useState(false)
    function transformData(response) {
        return response?.Customers?.map(customer => ({
            CustomerId: customer.CustomerId,
            Name: customer.Name,
            Email: customer.Email,
            Mobile: customer.Mobile,
            Address: customer.Address,
            msisdn: customer.AttributeList?.find(attr => attr.Name?.toLowerCase() === "msisdn")?.Value || "",
            imsi: customer.AttributeList?.find(attr => attr.Name?.toLowerCase() === "imsi")?.Value || "",
            SiteType: customer.AttributeList?.find(attr => attr?.Name?.toLowerCase() === "site type")?.Value || "",
            PowerSavingMode: customer.AttributeList?.find(attr => attr?.Name?.toLowerCase() === "power saving mode")?.Value || "",
            Tags: customer.Tags || []
        }));
    }

    const operatorConfiguration = useSelector((state) => state?.GetOperatorSpecificConfiguration?.OperatorSpecificConfiguration)
    const selfcarePermission = operatorConfiguration?.configurations?.find(config => config?.name === "selfcare")?.value[0]?.value[0] === 'True'
    const operatorId = localStorage.getItem('operatorId');

    const isEIPEnabled = operatorConfiguration?.configurations
        ?.find(config => config.name === "EIP")
        ?.value?.find(item => item.key === "Enabled")
        ?.value?.[0] === "True";

    const buttonLabel = isEIPEnabled ? "New Device" : "New Customer";
    const buttonLabel1 = isEIPEnabled ? "Recent updated devices." : "Recent updated customers.";

    useEffect(() => {
        dispatch(resetUpdatePasswordState());
    }, [passwordModal, dispatch]);

    const transformedData = transformData(TotalCustomers);
    console.log("transformedData",transformedData)

    return (
        <>
            <CardBody>
                {!getTable && searchExpanding ? (
                    <div className="border rounded static-height d-flex align-items-center justify-content-center">
                        <div className="flex-column" style={{ textAlign: 'center' }}>
                            <i className={`${errorIcon} icon-size flex-column`} />
                            <p className="text-color-telco ">
                                {errorMessage}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="container-fluid px-0">
                        <Card className="disable-margin-card w-100">
                            <Row className="mt-1 table-responsive">
                                <MaterialReactTable
                                    data={!getTable ? (transformedData || []) : (searchData || [])}
                                    columns={searchColumns}
                                    icons={{
                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                    }}
                                    renderTopToolbarCustomActions={({ }) => (
                                        <div className="col-12 col-md-auto mb-2">
                                            {customerPermission && customerPermission.permissionList.includes('create') &&
                                                <Button
                                                    color="secondary"
                                                    className="btn-label me-2 w-100"
                                                    onClick={() => navigateToCreateCustomer(navigate)}
                                                >
                                                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>
                                                    {buttonLabel}
                                                </Button>}
                                        </div>
                                    )}
                                    defaultColumn={{
                                        minSize: 10,
                                        maxSize: 9001,
                                        size: 260,
                                        muiTableBodyCellProps: {
                                            sx: {
                                                fontFamily: "Inter,sans-serif"
                                            }
                                        },
                                        muiTableHeadCellProps: {
                                            sx: {
                                                fontFamily: "Inter,sans-serif"
                                            }
                                        }
                                    }}
                                    initialState={{
                                        columnVisibility: {
                                            "mrt-row-expand": false,
                                            "Email": false
                                        },
                                    }}
                                    layoutMode='grid'
                                    muiTableDetailPanelProps={{
                                        sx: {
                                            fontFamily: "Inter,sans-serif",
                                        },
                                    }}
                                    enablePagination={false}
                                    enableGlobalFilter={false}
                                    enableColumnOrdering={true}
                                    enableColumnDragging={true}
                                    enableDensityToggle={false}
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            header: "",
                                            enableHiding: true,
                                        },
                                    }}
                                    enableRowActions={true}
                                    renderRowActionMenuItems={({ closeMenu, row }) => (
                                        [
                                            customerPermission && customerPermission.permissionList.includes('view') ? <MenuItem className='row-options' key="view" onClick={() => { simulateOrPreview = "View"; navigate(`/view-customer/${row.original.CustomerId}`) }}>
                                                <i className='ri  ri-account-circle-line me-2' /> View
                                            </MenuItem> : null,
                                            customerPermission && customerPermission.permissionList.includes('update') ? <MenuItem className='row-options' key="edit" onClick={() => { navigate(`/update-customer/${row.original.CustomerId}`) }}>
                                                <i className='bx bxs-edit me-2' /> Edit
                                            </MenuItem> : null,
                                            <MenuItem className='row-options' key="simulate" onClick={(e) => {
                                                // navigate(`/simulate/${row.original.CustomerId}`)
                                                closeMenu()
                                                dispatch(fetchDetailedBalance(row, setLoading, true, navigate, setModalForSimulate, isView, toggleModal));
                                                setMessageForPopUp(`Usage simulation requires an active subscription.`)
                                                setDontShowCloseButton(true)
                                            }}>
                                                <i className=' bx bx-info-circle me-2' /> Simulate
                                            </MenuItem>,
                                            <MenuItem className='row-options' key="preview" onClick={(e) => {
                                                simulateOrPreview = "Preview"
                                                e.stopPropagation();
                                                getCustomerData(row, simulateOrPreview, setLoading, stationPermission, reservationPermission, customerPermission, setRenderSimulate, dispatch)
                                                row.toggleExpanded(); storeRow(row, setIndex, index), setDefault(row.index, setActiveTab), closeMenu()
                                            }}>
                                                <i className="ri-survey-line me-2" /> Preview
                                            </MenuItem>,
                                            orderPermission && orderPermission.permissionList.includes('create') ? <MenuItem className='row-options' key="subscribe" onClick={() => {
                                                navigateToSubscribe(row, dispatch, navigate)
                                            }}>
                                                <i className="ri-add-circle-line me-2 " ></i> Subscribe
                                            </MenuItem> : null,
                                            selfcarePermission && customerPermission && customerPermission.permissionList.includes('update') ? <MenuItem className='row-options' key="password"
                                                onClick={() => {
                                                    closeMenu()
                                                    setPasswordCustomer(row?.original)
                                                    setPasswordModal(!passwordModal)
                                                }}
                                            >
                                                <i class="ri-lock-password-line me-2" />Configure Password
                                            </MenuItem> : null,
                                            customerPermission && customerPermission.permissionList.includes('delete') ? <MenuItem className='row-options'
                                                key="close"
                                                onClick={() => {
                                                    setMessageForPopUp(`Are you sure you want to Delete this Customer "${row.original.CustomerId}"?`)
                                                    toggleModal(row, "single");
                                                    setCustomerId(row.original.CustomerId)
                                                    setModalForSimulate(false)
                                                    setDontShowCloseButton(false)
                                                    closeMenu();
                                                }}
                                            >
                                                <i className="ri ri-delete-bin-5-line me-2" />{" "}
                                                Close
                                            </MenuItem> : null,
                                        ]
                                    )}
                                    renderDetailPanel={({ row }) => (
                                        <>
                                            {renderSimulate ? (
                                                <Simulate row={row} />
                                            ) : (
                                                <div className="preview-customer">
                                                    <CustomerPreview
                                                        customerData={customerData}
                                                        row={row}
                                                        activeTab={activeTab}
                                                        setActiveTab={setActiveTab}
                                                        tabChange={tabChange}
                                                        open={setOpen}
                                                        setOpen={setOpen}
                                                        toggle={toggle}
                                                        customerAttributeData={customerData?.customerInfo?.attributeList}
                                                        noCurrencyArray={noCurrencyArray}
                                                        currencyArray={currencyArray}
                                                        fetchCustomerSummaryData={fetchCustomerSummaryData}
                                                        reservationDataArray={reservationDataArray}
                                                        duration={duration}
                                                        subscriptionsArray={subscriptionsArray}
                                                        convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear}
                                                        formatDate={formatDate}
                                                        getDayDiff={getDayDiff}
                                                        current={current}
                                                    /></div>
                                            )}
                                        </>
                                    )}
                                />
                            </Row>
                            <div className="recent-customers-color d-flex justify-content-end align-items-center me-2 mb-1"><i className="ri ri-error-warning-fill me-2 fs-4"></i>{buttonLabel1}</div>
                        </Card>
                    </div>
                )}
            </CardBody>
            <CommonModal
                messageForPopUp={messageForPopUp}
                toggle={() => toggleModal(customerId, "single")}
                open={modal}
                newLineMessage={modalForSimulate && "Please subscribe to access this feature."}
                dontShowCloseButton={dontShowCloseButton}
                buttonText={modalForSimulate ? "Okay!" : "Yes, Delete It"}
                modalAction={() => {
                    if (modalForSimulate) {
                        toggleModal(customerId, "single");
                    }
                    else {
                        dispatch(deletecustomer(customerId, setLoading, 1, pagination));
                        toggleModal(customerId, "single");
                    }
                }}
            />
            {passwordCustomer && <PasswordModal passwordModal={passwordModal} setPasswordModal={setPasswordModal} setLoading={setLoading} navigate={navigate} passwordCustomer={passwordCustomer} setPasswordCustomer={setPasswordCustomer} />}
        </>
    );
};
