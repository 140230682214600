import { Card, CardBody, Row, Col, Input, CardHeader } from "reactstrap";
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { LabelText } from "../Utils/Input";
import { Loader } from "../Utils/Loader";
import { useEffect, useMemo, useState } from "react";
import { fetchAllOperatorDefaultConfigurations } from "../../slices/OperatorManagement/GetAllDefaultConfiguraions/thunk";
import { useDispatch, useSelector } from "react-redux";
import { brmCheck, categoriesList, combinedConfigurations, customerRetrievalCheck, handleMenuChange, handleSwitchToggle, limitCheck, mapConfig } from "./Constant/ConfigFunction";
import { configDisplayNameMapping } from "./Constant/Options";
import { ConfigSlider }from './ConfigSlider'

export default function ConfigurationForOperator({ setFinalValues, finalValues, values, configData, isEdit, setDefaultConfigurations}) {
    const [loading, setLoading] = useState(false);
    const [switchState, setSwitchState] = useState({});
    const [selectState, setSelectState] = useState({});
    const [useEffectEnabled, setUseEffectEnabled] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('');
    const [config, setConfig] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [offcanvasConfig, setOffcanvasConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [tempSwitchState, setTempSwitchState] = useState({});
    const [tempSelectState, setTempSelectState] = useState({});

    const dispatch = useDispatch();
    const isCreate = location.pathname.includes('create-operator')

    useEffect(() => {
        if (values?.Domain) {
            dispatch(fetchAllOperatorDefaultConfigurations(setLoading, values.Domain.label));
        }
    }, [dispatch, values?.Domain]);

    const role = localStorage.getItem('roleName')

    const OperatorDefaultConfigurationList = useSelector((state) => state.GetAllDefaultConfiguration.OperatorDefaultConfiguration);
    const OperatorDefaultConfigurationListFiltered = useMemo(() => {
        return OperatorDefaultConfigurationList?.filter(config => config.scope.includes(role)) || [];
    }, [OperatorDefaultConfigurationList,role]);

    useEffect(() => {
        setDefaultConfigurations(OperatorDefaultConfigurationListFiltered || []);
    }, [OperatorDefaultConfigurationListFiltered]);

    useEffect(() => {
        mapConfig(isEdit, configData, OperatorDefaultConfigurationListFiltered, setSwitchState, setSelectState)
    }, [OperatorDefaultConfigurationListFiltered, isEdit]);

    useEffect(() => {
        if (OperatorDefaultConfigurationListFiltered) {
            setSelectedMenu('viewAll');
            setConfig(combinedConfigurations(OperatorDefaultConfigurationListFiltered));
            setCategories(categoriesList(OperatorDefaultConfigurationListFiltered));
        }
    }, [OperatorDefaultConfigurationListFiltered]);

    useEffect(() => {
        brmCheck(switchState, setSwitchState,useEffectEnabled)
    }, [switchState?.TaskEnabled])

    useEffect(() => {
        customerRetrievalCheck(useEffectEnabled, isCreate, setSwitchState, values)
    }, [isCreate, values?.Domain,switchState?.CustomerSearch,switchState?.CustomerListing]);
    
    useEffect(()=>{
        limitCheck(useEffectEnabled,isCreate,setSwitchState);
    },[isCreate, switchState?.Limit,switchState?.ExpiryInSeconds])

    useEffect(() => {
        const combinedValues = {
            ...switchState,
            ...Object.fromEntries(
                Object.entries(selectState)?.map(([key, value]) => [key, value?.value])
            )
        };
        setFinalValues(combinedValues);
    }, [switchState, selectState]);

    useEffect(() => {
        if(searchTerm){
            setFilteredCategories(categories?.filter(category=>(
                category?.toLowerCase()?.includes(searchTerm?.toLowerCase())
            )))
        }else{
            setFilteredCategories(categoriesList(OperatorDefaultConfigurationListFiltered));
        }
    }, [searchTerm,OperatorDefaultConfigurationListFiltered])

    const toggleOffcanvas = (conf) => {
        setOffcanvasConfig(conf);
        setIsOpen(true);
        setTempSwitchState(switchState);
        setTempSelectState(selectState);
    };

    const closeOffcanvas = () => {
        setIsOpen(false);
        setOffcanvasConfig(null);
    };
    
    const handleInputChange = (configName, newValue) => {
        setUseEffectEnabled(true)
        setTempSwitchState((prevState) => ({
            ...prevState,
            [configName]: newValue
        }));
    };

    useEffect(()=>{
        if(switchState.Mode==="None" || switchState.Mode===""){
            setSwitchState((prevState)=>({
                ...prevState,
                ["Generate CSV File"] : false,
                ["Trigger One Bill API"] : false,
            }))
        }
    },[switchState.Mode])

    const handleRadioChange = (key, value) => {
        setTempSwitchState((prevState) => {
            const newState = { ...prevState, [key]: value };
            if (key === "CustomerSearch" && newState[key]) {
                newState["CustomerListing"] = false;
            } else if (key === "CustomerListing" && newState[key]) {
                newState["CustomerSearch"] = false;
            } else if (key === "CustomerSearch" && !newState[key]) {
                newState["CustomerListing"] = true;
            } else if (key === "CustomerListing" && !newState[key]) {
                newState["CustomerSearch"] = true;
            }
            return newState;
        });
    }

    const CustomerChange = (configName) => {
        setUseEffectEnabled(true);
        setTempSwitchState((prevState) => {
            const newState = { ...prevState, [configName]: !prevState[configName] };
    
            if (configName === "CustomerSearch" && newState[configName]) {
                newState["CustomerListing"] = false;
            } else if (configName === "CustomerListing" && newState[configName]) {
                newState["CustomerSearch"] = false;
            } else if (configName === "CustomerSearch" && !newState[configName]) {
                newState["CustomerListing"] = true;
            } else if (configName === "CustomerListing" && !newState[configName]) {
                newState["CustomerSearch"] = true;
            }
    
            return newState;
        });
    }

    const handleSave = () => {
        setSwitchState(tempSwitchState);
        setSelectState(tempSelectState);
        setIsOpen(false);
        setOffcanvasConfig(null);
    }   

    const handleSelectAllChange = () => {
        setTempSwitchState((prevState) => {
            const selectAllState = !prevState["Select All"]; 
            const updatedState = { ...prevState, ["Select All"]: selectAllState };
            const notificationManagement = OperatorDefaultConfigurationListFiltered?.find(
                (config) => config.name === "notificationManagement"
            );
            if (notificationManagement) {
                notificationManagement?.value.forEach((config) => {
                    updatedState[config.key] = selectAllState; 
                });
            }
            return updatedState;
        });
    };
    
    const handleSelectChange = (key, selectedOption, setSelectState) => {
        setTempSelectState(prevState => ({
            ...prevState,
            [key]: {label:selectedOption, value:selectedOption}
        }));
    };

    const handleCheckboxChange = (item) => {
        setTempSwitchState((prevState) => ({
            ...prevState,
            [item]: !prevState[item]
        }));
    };

    return (
        <div>
            <Card className="card-config">
                <CardHeader className="px-3 pt-3 fs-4">Configurations</CardHeader>
                {loading && <Loader />}
                <CardBody>
                    <Row className="gy-4">
                        <Col lg={2} md={3} className="border-end mt-2">
                        <div className="navbar-conf">
                            <div className='search-box mt-2' >
                                <span className='ri-search-line search-icon'></span>
                                <input
                                    type='text'
                                    className='form-control bg-light'
                                    placeholder='Search...'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <Navbar className="nav-conf">
                                <Nav navbar>
                                    {/* {filteredCategories.length === categories.length &&  */}
                                    <NavItem>
                                        <NavLink 
                                            className={`nav-items ${selectedMenu === 'viewAll' ? 'active' : ''}`} 
                                            onClick={() => handleMenuChange('viewAll', setSelectedMenu, OperatorDefaultConfigurationListFiltered, setConfig)}
                                        >
                                            View All
                                        </NavLink>
                                    </NavItem>
                                    {/* }    */}
                                    {filteredCategories?.map((category, index) => (
                                        <NavItem key={index}>
                                            <NavLink 
                                                className={`nav-items ${selectedMenu === category ? 'active' : ''}`} 
                                                onClick={() => handleMenuChange(category, setSelectedMenu, OperatorDefaultConfigurationListFiltered, setConfig)}
                                            >
                                                {category}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </Navbar>
                            </div>
                        </Col>
                        
                        <Col lg={10} md={9}>
                        <div className="cardbody-conf">
                            <Row>
                            {config?.map((configuration, index) => (
                                (configuration.name !== "identityManagement" && configuration.name !== "DefaultRequestedUnit") &&
                                ((configuration.name==="paymentManagement" || configuration.name === "identityManagement" || configuration.name === "UsageEventsPersistanceMode" || configuration.name === "customerRetrieval" || configuration.name === "notificationManagement" || configuration.name === "Prefixes" || configuration.name === "SequenceHandling" || configuration.name === "SessionAuditBuffer" || configuration.name === "ReservationUnitsExpiration" || (configuration.name === "Rated Event Consumer Configuration" && switchState["Mode"] && switchState["Mode"] !== "None" )) ? (
                                    <Col lg={3} md={6} key={`${index}`} className="my-2">
                                    <Card className="border card-conf">
                                        <CardBody >
                                            <div className="d-flex align-items-center mb-4">
                                                <div>
                                                {configuration.name === "paymentManagement" && <i className="ri-bank-card-line icon-conf me-3" />}
                                                {(configuration.name === "customerRetrieval" || configuration.name === "identityManagement") && <i class="ri-user-line icon-conf me-3"></i>}
                                                {configuration.name === "UsageEventsPersistanceMode" && <i className="ri-pie-chart-2-line icon-conf me-3" />}
                                                {configuration.name === "notificationManagement" && <i className="ri-notification-line icon-conf me-3" />}
                                                {configuration.name === "Rated Event Consumer Configuration" && <i className="ri-exchange-funds-line icon-conf me-3" />}
                                                {(configuration.name === "Prefixes" || configuration.name === "SequenceHandling") && <i class="ri-add-circle-line icon-conf me-3"></i>}
                                                {configuration.name === "ReservationUnitsExpiration" && <i className="ri-refresh-line icon-conf me-3" />}
                                                {configuration.name === "SessionAuditBuffer" && <i className="ri-timer-2-line icon-conf me-3" />}
                                                </div>
                                            </div>
                                            <div className="form-switch d-flex ms-0 ps-0 justify-content-between">
                                                <LabelText 
                                                    displayText={configDisplayNameMapping[configuration.name] || configuration.name} 
                                                    className="me-5 mb-1" 
                                                />
                                                {/* <Input
                                                    type="switch"
                                                    checked={(configuration.name === "paymentManagement" || configuration.name === "identityManagement") ? (selectState[configuration.value[0].key]?.value && selectState[configuration.value[0].key]?.value!==""):(configuration.name === "UsageEventsPersistanceMode")?(switchState["Mode"] && switchState["Mode"] !== "None"):(configuration.name === "customerRetrieval" || configuration.name === "Rated Event Consumer Configuration")?(switchState[configuration.value[0].key] || switchState[configuration.value[1].key]):(configuration.name === "notificationManagement")? (switchState["NotificationEnable"] || false) : switchState[configuration.name]}                                                    
                                                    onChange={() => toggleOffcanvas(configuration) }
                                                /> */}
                                                <i className="ri-eye-line fs-4" onClick={() => toggleOffcanvas(configuration) }/>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                ): 
                                configuration?.value?.map((conf, confIndex) => {
                                    if(conf.key !== "PaymentEnabled" && conf.key !=="Generate CSV File" && conf.key !== "Trigger One Bill API"){
                                    const isBoolean = conf.value[0] === "True";
                                    const isArray = conf.value.length > 1;
                                    const isSingle = conf.value.length === 1;
                                    return (
                                        <Col lg={3} md={6} key={`${index}-${confIndex}`} className="my-2">
                                            <Card className="border card-conf">
                                                <CardBody >
                                                    <div className="d-flex align-items-center mb-4">
                                                    <div className={`${configuration.name === "LLM" ? "mt-1 mb-2" : ""}`}>
                                                        {configuration.name === "taskManagement" && <i class="ri-whatsapp-line icon-conf me-3"/> }
                                                        {configuration.name === "whatsAppManagement" && <i class="ri-whatsapp-line icon-conf me-3"/> }
                                                        {configuration.name === "policySpecifications" && <i class="ri-article-line icon-conf me-3"/>}
                                                        {configuration.name === "selfcare" && <i className="ri-dashboard-line icon-conf me-3" />}
                                                        {configuration.name === "SessionAudit" && <i className="ri-timer-2-line icon-conf me-3" />}
                                                        {configuration.name === "BillingManagement" && <i className="ri-bill-line icon-conf me-3" />}
                                                        {configuration.name === "orderManagement" && <i className="ri-shopping-cart-line icon-conf me-3" />}
                                                        {configuration.name === "ReservationUnitsExpiration" && <i className="ri-refresh-line icon-conf me-3" />}
                                                        {configuration.name === "LLM" && <i className="mdi mdi-star-four-points-outline icon-conf me-3 px-2 fs-3 mt-6" />}
                                                        {configuration.name === "taxManagement" && <i class="ri-currency-line icon-conf me-3"/>}
                                                        {configuration.name === "EIP" && <i class="ri-currency-line icon-conf me-3"/>}
                                                        </div>
                                                    </div>
                                                    <div className="form-switch d-flex ms-0 ps-0 justify-content-between">
                                                        <LabelText 
                                                            displayText={configDisplayNameMapping[configuration.name] || configDisplayNameMapping[conf.key] || conf.key} 
                                                            className="me-5" 
                                                        />
                                                        {isSingle ? (
                                                            <Input
                                                                type="switch"
                                                                checked={switchState[conf.key] || switchState[configuration.name] || false}
                                                                onChange={() => toggleOffcanvas(configuration)}
                                                            />
                                                        ) :(
                                                            <Input
                                                                type="switch"
                                                                checked={configuration.name === "taskManagement" ? switchState[conf.key]:switchState[configuration.name] || false}
                                                                disabled={configuration.name === "taskManagement" ? (!switchState["TaskEnabled"] && confIndex !== 0) : false}
                                                                onChange={() => {
                                                                    if (isBoolean) {
                                                                        if(configuration.name === "taskManagement"){ 
                                                                            handleSwitchToggle(conf.key, setUseEffectEnabled, setSwitchState)
                                                                        } else {
                                                                            handleSwitchToggle(configuration.name, setUseEffectEnabled,setSwitchState)
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    );
                                    }}
                                ))
                                ))}
                            </Row>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                {offcanvasConfig && (
                    <ConfigSlider
                        isOpen={isOpen}
                        closeOffcanvas={closeOffcanvas}
                        offcanvasConfig={offcanvasConfig}
                        handleSave={handleSave}
                        switchState={switchState}
                        handleSwitchToggle={handleSwitchToggle}
                        handleInputChange={handleInputChange}
                        handleRadioChange={handleRadioChange}
                        handleSelectAllChange={handleSelectAllChange}
                        handleCheckboxChange={handleCheckboxChange}
                        selectState={selectState}
                        handleSelectChange={handleSelectChange}
                        setSelectState={setSelectState}
                        setUseEffectEnabled={setUseEffectEnabled}
                        setSwitchState={setSwitchState}
                        tempSelectState = {tempSelectState}
                        tempSwitchState = {tempSwitchState}
                        CustomerChange = {CustomerChange}
                    />
                )}
            </Card>
        </div>
    );
}