import { Formik } from "formik"
import { Col, Container, Form, Row, CardBody, Button } from "reactstrap"
import { validationForOffer } from "./constants/validationSchema";
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import OfferDetails from "./OfferDetails";
import { isEmptyOrInvalidField, isEmptyorInvalidFieldForGrantsAndCharges, optionsForSelect, handleStartDateChange, uomOptionsForUsage, resourceArrayData, grantArrayData, chargeArrayData, serviceDataOptions, policyDataOptions, aggregateResourceDataOptions } from "./constants/checkEmptyFields";
import { TimePeriodOptions, recurringFrequencyOptions, infiniteOptions, TimePeriodEndOptions } from "./constants/initialValues";
import { displayInfiniteForOptions, displayCeilingAndFloorForOptions, handleSelectedService, chargesTabChange,usageTabChange, handleChargesProrateState, handleProrateState, handleGrantsOverrideState, handleChargesOverrideState, handleGrantsRecurringOverride, handleChargesRecurringOverride, toggleAccordion } from "./constants/displayInfinite";
import OfferAccordion from "./OfferAccordion";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUom } from "../../../slices/uom/thunk";
import { getAllResources } from "../../../slices/resources/thunk";
import { getAllService } from "../../../slices/services/thunk";
import { ToastContainer } from "react-toastify";
import { tabChange } from "../../Utils/SetDefaultRowData";
import { Loader } from "../../Utils/Loader";
import { onSubmtiFunctionForOffer } from "./constants/GrantsAndRecurringFunctions";
import ServiceForOffer from "./ServiceForOffer";
import CommonButton from "../../Utils/CommonButton";
import SubscriptionValidity from "./SubscriptionValidity";
import { getOffer } from "../../../slices/offer/getoffer/thunk";
import { customerIdData } from "../../Customers/constants/constantFunctionsView";
import { EffectiveAt } from "./EffectiveAt";
import { PAGE_TITLES } from "../../Common/constants";
import { getAllPolicies } from "../../../slices/Policy/thunk";
import PolicyForOffer from "./PolicyForOffer";
import { getAggregateResource } from "../../../slices/resources/AggregateResource/thunk";
import AggregateResourcesForOffer from './AggregateResourcesForOffer'
export default function AddOffer() {

    const [state, setState] = useState(false)
    const [stateCharges, setStateCharges] = useState(false)
    const [overrideCharge, setOverrideCharge] = useState(false)
    const [overrideChargeRecurring, setOverrideChargeRecurring] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [globalState, setGlobalState] = useState(false)
    const [addOnState, setAddOnState] = useState(false)
    const [serviceCode, setServiceCode] = useState('')
    const [activeTab, setActiveTab] = useState("1")
    const [chargesActiveTab, setChargesActiveTab] = useState("1")
    const [usageActiveTab, setUsageActiveTab] = useState("1")
    const [open, setOpen] = useState('1');
    const [prorateState, setProrateState] = useState(false)
    const [overrideState, setOverrideState] = useState(false)
    const [overrideStateRecurring, setOverrideStateRecurring] = useState(false)
    const [selectedService, setSelectedService] = useState([])
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [stop, setStop] = useState(false)
    const [isSubscribed,setIsSubscribed] = useState(false)
    const [disableValidityInGrantOne,setDisableValidityInGrantOneTime] = useState([])
    const [disableValidityInGrantRecurring,setDisableValidityInGrantRecurring] = useState([])
    const rolename = localStorage.getItem('roleName');

    const initialValues = {
        offerName: '',
        offerCode: '',
        offerPriority:'',
        subscriptionStartsOn: '',
        timePeriodStart: 1,
        timePeriodOptionStart: TimePeriodOptions[0],
        subscriptionEndsOn: '',
        timePeriod: 1,
        timePeriodOption: TimePeriodEndOptions[0],
        description: '',
        offerStartsOn: null,
        offerEndsOn: null,
        usageId: "1",
        eventType: '',
        resourceName: '',
        unitOfMeasurement: '',
        priority: "1",
        perUnitRate: '',
        flatRate: '',
        serviceName: '',
        policyName: '',
        aggregateVolume:[],
        usageRatePlan: [],
        recurringUsageRatePlan: [],
        grants: [],
        recurringGrants: [],
        charges: [],
        recurringCharges: [],
        effectiveAt: null,
        pricingModel: [],
        categories:''
    }
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const offerCode = customerIdData()
    const isClone = location.pathname.includes('clone-offer')
    const isEdit = location.pathname.includes('update-offer')
    const isCreate = location.pathname.includes('create-offer')
    const [formValues, setFormValues] = useState(initialValues)

    const toggle = toggleAccordion(open, setOpen, globalState)

    let prorateStateArray = []
    let uomArray = []
    let resourceNameArray = []
    let grantResourceTypeArray = []
    let chargeResourceTypeArray = []

    useEffect(() => {
        dispatch(fetchAllUom(setLoading))
        dispatch(getAllResources(setLoading))
        dispatch(getAllPolicies(setLoading))
        dispatch(getAggregateResource(setLoading))
        dispatch(getAllService(setLoading))
    }, [dispatch])

    useEffect(() => {
        if (isEdit) {
            setTitle(PAGE_TITLES.EDIT_OFFER)
        }
        else if (isClone) {
            setTitle(PAGE_TITLES.CLONE_OFFER)
        }
        else {
            setTitle(PAGE_TITLES.ADD_OFFER)
        }
    }, [isEdit])

    const uomData = useSelector((state) => state.Uom.uom)
    const resourceData = useSelector((state) => state.Resources.resources)
    const serviceData = useSelector((state) => state.Services.services)
    const Policies = useSelector((state) => state?.GetAllPolicies?.getAllPolicies);
    const aggregateResource=useSelector((state) => state?.GetAggregateResource?.aggregateResources);
    let serviceOptions = serviceDataOptions(serviceData)
    let policiesOptions = policyDataOptions(Policies)
    let aggregateResourceOptions=aggregateResourceDataOptions(aggregateResource)
    let resourceNameOptions = optionsForSelect(resourceArrayData(resourceData, resourceNameArray))
    let uomOptions = optionsForSelect(uomOptionsForUsage(uomData, uomArray))
    let grantResourceOptions = optionsForSelect(grantArrayData(resourceData, grantResourceTypeArray))
    let chargeResourceOptions = optionsForSelect(chargeArrayData(resourceData, chargeResourceTypeArray))

    //UPDATE
    useEffect(() => {
        if ((isEdit || isClone) && serviceData && serviceData.length) {
            dispatch(getOffer(setLoading, offerCode, isEdit, initialValues, resourceData, serviceData, formValues, setFormValues, setStop, stop, setDisableValidityInGrantOneTime, setDisableValidityInGrantRecurring))
        }
    }, [])

    const getOfferData = useSelector((state) => state.GetOffer.editOffer)

    useEffect(() => {
        if (globalState) {
            setOpen(false);
        }
    }, [globalState, getOfferData]);

    useEffect(() => {
        if (isEdit || isClone) {
            setGlobalState(getOfferData.globalScope || false);
            setAddOnState(getOfferData.addOnOffer || false);
            setState(getOfferData?.validity?.to === "0001-01-01T00:00:00Z" ? true :  false)
            setIsSubscribed(getOfferData?.isSubscribed)
        }
    }, [isEdit, isClone, getOfferData])

    return (
        <div>
            <Formik initialValues={initialValues} validationSchema={validationForOffer(state, isEdit,isClone, disableValidityInGrantOne,disableValidityInGrantRecurring)}
                onSubmit={onSubmtiFunctionForOffer(dispatch, selectedService, resourceData, state, globalState, serviceCode, setLoading, navigate, isEdit, serviceData, addOnState,isClone)}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldTouched, setFieldValue, setTouched, resetForm }) => (
                    <Form id='csms' className="needs-validation" onSubmit={handleSubmit}>
                        <div>

                            <div className="page-content">
                                <Container fluid>
                                    <BreadCrumb title={title} pageTitle={PAGE_TITLES.OFFER_LIST} />
                                    <ToastContainer position="top-center" />
                                    <Row>
                                        <Col lg={12}>
                                            <CardBody className="card-body">
                                                <div className="live-preview">
                                                    <OfferDetails
                                                        values={values} setFormValues={setFormValues}
                                                        touched={touched} isEdit={isEdit}
                                                        errors={errors} isSubscribed={isSubscribed}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        state={state}
                                                        setState={setState}
                                                        startDate={startDate}
                                                        setStartDate={setStartDate}
                                                        handleStartDateChange={handleStartDateChange}
                                                        setFieldTouched={setFieldTouched}
                                                        setFieldValue={setFieldValue}
                                                        TimePeriodOptions={TimePeriodOptions}
                                                        globalState={globalState}
                                                        addOnState={addOnState}
                                                        setGlobalState={setGlobalState}
                                                        setAddOnState={setAddOnState}

                                                    />
                                                    <Row>
                                                        <Col xxl={12} md={12}>
                                                            <ServiceForOffer
                                                                values={values} errors={errors} touched={touched}
                                                                serviceData={serviceData} setServiceCode={setServiceCode} setSelectedService={setSelectedService}
                                                                serviceOptions={serviceOptions} isEdit={isEdit}
                                                                handleSelectedService={handleSelectedService} isSubscribed={isSubscribed}
                                                                setFieldValue={setFieldValue}
                                                                handleChange={handleChange}
                                                                setFieldTouched={setFieldTouched}
                                                            />
                                                        </Col>
                                                    </Row>
                                                   
                                                        <Row>
                                                        {rolename === 'telcoAdmin' ?
                                                            <Col xxl={6} md={6}>
                                                                <PolicyForOffer
                                                                    values={values} errors={errors} touched={touched} globalState={globalState}
                                                                    serviceData={Policies} setServiceCode={setServiceCode} setSelectedService={setSelectedService}
                                                                    policiesOptions={policiesOptions} isEdit={isEdit} isSubscribed={isSubscribed}
                                                                    handleSelectedService={handleSelectedService}
                                                                    setFieldValue={setFieldValue}
                                                                    handleChange={handleChange}
                                                                    setFieldTouched={setFieldTouched}
                                                                />
                                                            </Col>: null}
                                                            <Col xxl={6} md={6}>
                                                                <AggregateResourcesForOffer
                                                                    values={values} errors={errors} touched={touched} globalState={globalState}
                                                                    serviceData={Policies} setServiceCode={setServiceCode} setSelectedService={setSelectedService}
                                                                    aggregateResourceOptions={aggregateResourceOptions} isEdit={isEdit} isSubscribed={isSubscribed}
                                                                    handleSelectedService={handleSelectedService}
                                                                    setFieldValue={setFieldValue}
                                                                    handleChange={handleChange}
                                                                    setFieldTouched={setFieldTouched}
                                                                />
                                                            </Col>
                                                        </Row> 
                                                    <Row>
                                                        <Col xxl={12} md={12} >
                                                            <SubscriptionValidity isEdit={isEdit} isSubscribed={isSubscribed}values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} handleChange={handleChange} />
                                                        </Col>
                                                    </Row>
                                                    <OfferAccordion
                                                        isCreate={isCreate}
                                                        disableValidityInGrantOne={disableValidityInGrantOne}
                                                        setDisableValidityInGrantOneTime={setDisableValidityInGrantOneTime}
                                                        disableValidityInGrantRecurring={disableValidityInGrantRecurring}
                                                        setDisableValidityInGrantRecurring={setDisableValidityInGrantRecurring}
                                                        loading={loading} setServiceCode={setServiceCode} globalState={globalState}
                                                        setLoading={setLoading} setSelectedService={setSelectedService}
                                                        open={open} setActiveTab={setActiveTab} setChargesActiveTab={setChargesActiveTab}
                                                        toggle={toggle} isEdit={isEdit}
                                                        resourceData={resourceData}
                                                        activeTab={activeTab}
                                                        chargesActiveTab={chargesActiveTab}
                                                        tabChange={tabChange}
                                                        chargesTabChange={chargesTabChange}
                                                        values={values}
                                                        prorateState={prorateState}
                                                        setProrateState={setProrateState}
                                                        prorateStateArray={prorateStateArray}
                                                        overrideState={overrideState}
                                                        setOverrideState={setOverrideState}
                                                        overrideStateRecurring={overrideStateRecurring}
                                                        setOverrideStateRecurring={setOverrideStateRecurring}
                                                        serviceData={serviceData}
                                                        serviceOptions={serviceOptions}
                                                        handleSelectedService={handleSelectedService}
                                                        setFieldValue={setFieldValue}
                                                        setFieldTouched={setFieldTouched}
                                                        touched={touched}
                                                        errors={errors}
                                                        handleProrateState={handleProrateState}
                                                        handleGrantsOverrideState={handleGrantsOverrideState}
                                                        handleChargesProrateState={handleChargesProrateState}
                                                        handleChargesOverrideState={handleChargesOverrideState}
                                                        handleGrantsRecurringOverride={handleGrantsRecurringOverride}
                                                        handleChargesRecurringOverride={handleChargesRecurringOverride}
                                                        infiniteOptions={infiniteOptions}
                                                        grantResourceOptions={grantResourceOptions}
                                                        setTouched={setTouched}
                                                        handleChange={handleChange}
                                                        stateCharges={stateCharges}
                                                        setStateCharges={setStateCharges}
                                                        overrideCharge={overrideCharge}
                                                        setOverrideCharge={setOverrideCharge}
                                                        overrideChargeRecurring={overrideChargeRecurring}
                                                        setOverrideChargeRecurring={setOverrideChargeRecurring}
                                                        state={state}
                                                        setState={setState}
                                                        recurringFrequencyOptions={recurringFrequencyOptions}
                                                        chargeResourceOptions={chargeResourceOptions}
                                                        isEmptyOrInvalidField={isEmptyOrInvalidField}
                                                        resourceNameOptions={resourceNameOptions}
                                                        uomOptions={uomOptions}
                                                        isEmptyorInvalidFieldForGrantsAndCharges={isEmptyorInvalidFieldForGrantsAndCharges}
                                                        isSubscribed={isSubscribed}
                                                        setUsageActiveTab ={setUsageActiveTab}
                                                        usageActiveTab={usageActiveTab}
                                                        usageTabChange={usageTabChange}
                                                        isClone={isClone}
                                                    />
                                                    <Row className="mt-4">
                                                        <Col xxl={12} md={12} >
                                                            {isEdit &&
                                                                <EffectiveAt touched={touched} errors={errors} values={values} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                    <Row lg={6} className=" justify-content-end mb-4 mt-4">
                                        <div className="live-preview">
                                            <div className="d-flex justify-content-end gap-2 ">
                                                <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/offer')} buttonText={"Cancel"} />
                                                <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                                            </div>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {loading && (<Loader />)}
        </div>
    )
}