import { toast } from "react-toastify";
import { fetchcreateConfigOperatorSucess, fetchcreateConfigOperatorFailure } from "./reducer";
import { CREATE_CONFIG_OPERATOR } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";

export const createConfigOperator = (jsonObj, setLoading,navigate,isOperator) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${CREATE_CONFIG_OPERATOR}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchcreateConfigOperatorSucess(response))
            if(isOperator){
                navigate('/dashboard')
            }else{
                navigate('/operator-listing');
            }
        }
        if (response.error) {
            dispatch(fetchcreateConfigOperatorFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    })
}
