import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";
//Tables
import BasicTables from "../pages/Tables/BasicTables/BasicTables";

//AuthenticationInner pages
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Charts
import LineCharts from "../pages/Charts/ApexCharts/LineCharts";

import CreateCustomer from "../pages/Customer/createCustomer";
import Listing from "../pages/Customer/Listing";
import StationListing from "../pages/Stations/StationListing";
import ReservationListing from "../pages/Reservation/ReservationListing";
import UomListing from "../pages/Pricings/UOM/UomListing";
import ResourcesListing from "../pages/Pricings/Resources/ResourcesListing";
import ServiceListing from "../pages/Pricings/Service/ServiceListing";
import OfferListing from "../pages/Pricings/Offer/OfferListing";
import ViewCustomer from "../pages/Customer/ViewCustomer";
import CreateReservation from "../pages/Reservation/createReservation";
import Simulate from "../Components/Customers/Simulate";
import CreateOffer from "../pages/Pricings/Offer/CreateOffer";
import CreateResource from "../pages/Pricings/Resources/CreateResource";
import CreateService from "../pages/Pricings/Service/CreateService";
import CreateUom from "../pages/Pricings/UOM/CreateUom";
import EditStation from "../Components/Stations/EditStation";
import ErrorPage from "../pages/AuthenticationInner/Errors/404ErrorPage";
import InvoiceList from "../pages/Customer/InvoiceList";
import OrderDetails from "../Components/OrderManagement/OrderDetail";
import OrderFiltering from "../Components/OrderManagement/OrderFilters";
import SelectedOrders from "../Components/OrderManagement/SelectOrder";
import OrderListing from "../Components/OrderManagement/OrderListing";

import PartnersListing from "../Components/Partners/partners";
import CreatePartners from "../Components/Partners/createPartners";
import PartnerView from "../Components/Partners/ViewPartner";
import { PolicyListing } from "../Components/Partners/PolicyListing";
import AddPartnerForPolicy from "../Components/Partners/AddPartnerForPolicy";
import AddOfferForPolicy from "../Components/Partners/AddOfferForPolicy";
import { CreateEditPolicy } from "../Components/Partners/CreateEditPolicy";
import CreateDevice from "../Components/Device/CreateDevice";
import DeviceListings from "../Components/Device/ListingDevice";
import LightWeightOrders from "../pages/LightWeightCustomer/LightWeightOrders";
import LightWeightInvoices from "../pages/LightWeightCustomer/LightWeightInvoics";
import LightWeightPayments from "../pages/LightWeightCustomer/LightWeightPayments";
import LightWeightProfiles from "../pages/LightWeightCustomer/LightWeightProfiles";
import AttributeListing from "../pages/Pricings/Attribute/AttributeListing";
import CreateAttribute from "../pages/Pricings/Attribute/CreateAttribute";
import Import from "../pages/Pricings/Offer/Import";
import PolicyListings from "../pages/Pricings/Policy/PolicyListings";
import CreatePolicies from "../pages/Pricings/Policy/CreatePolicies";
import DashboardWrapper from "./dashboardWrapper";
import UserManagementListings from "../pages/UserManagement/UserManagementListings";
import UserCreateEdit from "../pages/UserManagement/CreateUserManagement";
import ViewUser from "../Components/UserManagement/ViewUser";
import OperatorListing from "../pages/OperatorManagement/operatorManagementListing";
import CreateEditOperator from "../pages/OperatorManagement/createEditOperator";
import OperatorViewandUser from "../pages/OperatorManagement/viewOperators";
import CustomerAi from "../pages/AI/customerAI";
import { SubscriptionWhileCustomerCreation } from "../Components/Customers/SubscriptionWhileCustomerCreation";
import { ChangeSummary } from "../Components/Customers/ChangeSummary";
import { ViewTasks } from "../Components/Customers/ViewTasks";
import UserProfile from "../Components/Profile/UserProfile";
import OperatorProfile from "../Components/BusinessProfile/operatorProfile";
import ResetPasswordPage from "../pages/ResetPassword/resetPasswordPage";
import SetPasswordPage from "../pages/SetPassword/SetPassword";
import { EmailVerification } from "../pages/EmailVerification/EmailVerification";
import ForgetPasswordPage from "../pages/ForgetPassword/ForgetPassword";
import SelfcareSubscriptionListing from "../pages/LightWeightCustomer/SubscriptionListSelfcare";
import SelfcareDocumentupload from "../pages/LightWeightCustomer/documents";
import { Sessions } from "../Components/Customers/Sessions";
import LandingPage from "../pages/LandingPage/LandingPage";
import SignUpPage from "../pages/LandingPage/SignUpPage";
import ChangePlan from "../Components/LightWeightCustomer/ChangePlan";
import CreateConfigurations from "../pages/OperatorManagement/createConfiguration";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardWrapper /> },

  //charts
  { path: "/charts-apex-line", component: <LineCharts /> },

  //Tables
  { path: "/tables-basic", component: <BasicTables /> },

  //Customer
  { path: "/create-customer", component: <CreateCustomer /> },
  { path: "/update-customer/:id", component: <CreateCustomer /> },
  { path: "/view-customer/:id", component: <ViewCustomer /> },
  { path: "/simulate/:id", component: <ViewCustomer /> },
  { path: "/sessions/:id", component: <Sessions/> },
  { path: "/invoices", component: <InvoiceList /> },
  { path: "/change-offer/:id", component: <SubscriptionWhileCustomerCreation /> },
  { path: "/change-offer-summary/:id", component: <ChangeSummary/> },
  { path: "/selfcare-changeplan/:id", component: <ChangePlan/> },
  { path: "/selfcare-addon/:id", component: <ChangePlan/> },
  { path: "/tasks", component: <ViewTasks /> },
  //Listing customer
  { path: "/customer", component: <Listing /> },
  //station Listing
  { path: "/station", component: <StationListing /> },
  //reservation listing
  { path: "/reservation", component: <ReservationListing /> },
  { path: "/update-reservation/:id", component: <CreateReservation /> },
  //create Reservation
  { path: "/create-reservation", component: <CreateReservation /> },
  {
    path: "/create-reservation-customer/:id",
    component: <CreateReservation />,
  },
  //Attribute
  { path: "/attribute", component: <AttributeListing /> },
  { path: "/create-attribute", component: <CreateAttribute /> },
  { path: "/update-attribute/:id", component: <CreateAttribute /> },
  //policy 
  { path: "/policies", component: <PolicyListings /> },
  //adding policy
  { path: "/add-policy", component: <CreatePolicies /> },
  { path: "/edit-policies/:id", component: <CreatePolicies /> },

  //UOM Listing
  { path: "/uom", component: <UomListing /> },
  //Add unit
  { path: "/create-unit", component: <CreateUom /> },
  //Edit unit
  { path: "/edit-uom/:id", component: <CreateUom /> },
  //resource Listing
  { path: "/resource", component: <ResourcesListing /> },
  //Add Resources
  { path: "/create-resources", component: <CreateResource /> },
  //Edit Resource 
  { path: "/edit-resource/:id", component: <CreateResource /> },
  //service Listing
  { path: "/service", component: <ServiceListing /> },
  //Add service
  { path: "/create-service", component: <CreateService /> },
  { path: "/clone-service/:id", component: <CreateService /> },
  {path:"/edit-service/:id",component:<CreateService/>},
  //offer Listing
  { path: "/offer", component: <OfferListing /> },
  { path: "/create-offer", component: <CreateOffer /> },
  { path: "/update-offer/:id", component: <CreateOffer /> },
  { path: "/clone-offer/:id", component: <CreateOffer /> },
  { path: "/import-data", component: <Import /> },
  { path: "/station/:id", component: <EditStation /> },
  { path: "/404-Error", component: <ErrorPage /> },
  // order Management
  //partners
  { path: "/partner-listing", component: <PartnersListing /> },
  { path: "/create-partner", component: <CreatePartners /> },
  { path: "/update-partner/:id", component: <CreatePartners /> },
  { path: "/view-partner/:id", component: <PartnerView /> },
  { path: "/policy", component: <PolicyListing /> },
  { path: "/partner-for-policy", component: <AddPartnerForPolicy /> },
  { path: "/offer-for-policy", component: <AddOfferForPolicy /> },
  { path: "/update-partner-for-policy", component: <AddPartnerForPolicy /> },
  { path: "/update-offer-for-policy", component: <AddOfferForPolicy /> },
  { path: "/create-policy", component: <CreateEditPolicy /> },
  { path: "/update-policy/:id", component: <CreateEditPolicy /> },

  { path: "/order-details", component: <OrderDetails /> },
  { path: "/order-filtering/:id", component: <OrderFiltering /> },
  { path: "/add-on/:id", component: <OrderFiltering /> },
  { path: "/order-select", component: <SelectedOrders /> },
  { path: "/view-order/:id", component: <OrderDetails /> },
  { path: "/cancel-order/:id", component: <OrderDetails /> },
  { path: "/orders", component: <OrderListing /> },
  { path: "/create-device", component: <CreateDevice /> },
  { path: "/devices", component: <DeviceListings /> },
  { path: "/update-device/:deviceId", component: <CreateDevice /> },
  //user Management
  { path: "/user", component: <UserManagementListings /> },
  { path: "/add-user", component: <UserCreateEdit /> },
  { path: "/edit-user/:id", component: <UserCreateEdit /> },
  { path: "/view-user/:id", component: <ViewUser /> },
  {path:"/profile",component:<UserProfile />},

  //lightweight Customer
  { path: "/customer/order", component: <LightWeightOrders />, title: "Orders" },
  { path: "/customer/invoice", component: <LightWeightInvoices />, title: "Invoice" },
  { path: "/customer/payment", component: <LightWeightPayments />, title: "Payment" },
  { path: "/customer/profile", component: <UserProfile />, title: "Profile" },
  { path: "/subscription", component: <SelfcareSubscriptionListing />, title: "Subscription" },
  { path: "/documents", component: <SelfcareDocumentupload /> ,title: "Documents"},
  //Operator Management
  { path: "/operator-listing", component: <OperatorListing />, title: "Listing" },
  { path: "/create-operator", component: <CreateEditOperator />, title: "" },
  { path: "/edit-operator/:id", component: <CreateEditOperator />, },
  { path: "/view-operator/:id", component: <OperatorViewandUser />, },
  { path:"/business-profile", component: <OperatorProfile />},
  { path: "/create-configurations", component: <CreateConfigurations />},
  //AI
  { path: "/chatbot", component: <CustomerAi />, },  
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/home" />,
  // },
  // { path: "*", component: <Navigate to="/home" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  {path:"/forgot-password",component: <ForgetPasswordPage />},
  //Reset Password
  {path:"/password-reset/",component: <ResetPasswordPage />},
  {path:"/password-set/",component: <SetPasswordPage />},

  //Email Verification
  {path:"/email-verification/",component: <EmailVerification />},
  //AuthenticationInner pages
  { path: "/auth-404-alt", component: <Alt404 /> },
  //Landing page
  { path: "/home", component: <LandingPage/> },
  { path: "/enquiry", component: <SignUpPage/> },

];

export { authProtectedRoutes, publicRoutes };
