import { Row, Col, Input } from "reactstrap";
import { LabelText } from "../Utils/Input";
import { Slider } from "./Slider";
import { configDisplayNameMapping, notificationDisplayNameMapping } from "./Constant/Options";

export const ConfigSlider = ({ isOpen, closeOffcanvas, offcanvasConfig, handleSave, switchState, handleSwitchToggle, handleInputChange, handleRadioChange, handleSelectAllChange, handleCheckboxChange, selectState, handleSelectChange, setSelectState, setUseEffectEnabled , setSwitchState, tempSelectState, tempSwitchState, CustomerChange}) => {
    
    return (
        <Slider
            isOpen={isOpen}
            setOpen={closeOffcanvas}
            actionName={
                configDisplayNameMapping[offcanvasConfig?.name] || 
                configDisplayNameMapping[offcanvasConfig?.value[0]?.key] ||
                offcanvasConfig?.name
            }
            handleSave={handleSave}
        >
            <Row>
                    {offcanvasConfig?.name === "paymentManagement" || offcanvasConfig?.name === "identityManagement" ? (
                        offcanvasConfig?.value?.map((item, idx) => (
                            (item.key === "PaymentProvider" || item.key === "IdentityProvider") && (
                                <Col lg={6} md={8}>
                                <div key={idx} className="mb-2">
                                    <LabelText displayText={item.key === "PaymentProvider" ? "Payment Provider" : "Identity Provider"} />
                                    {item?.value?.map((provider, providerIdx) => (
                                        <div key={providerIdx} className="border rounded-4 p-2 mb-2">
                                            <Input
                                                type="radio"
                                                checked={tempSelectState[item.key]?.value === provider}
                                                className="me-2"
                                                onChange={() => handleSelectChange(item.key, provider, setSelectState)}
                                            />
                                            <LabelText displayText={provider} />
                                        </div>
                                    ))}
                                </div>
                                </Col>
                            )
                        ))
                    ) : offcanvasConfig?.name === "UsageEventsPersistanceMode" ? (
                        offcanvasConfig?.value?.map((item, idx) => (
                            item.key === "Mode" && (
                                <Col lg={6} md={8}>
                                <div key={idx} className="mb-2">
                                    <LabelText displayText="Mode" />
                                    {item.value.map((mode, modeIdx) => (
                                        <div key={modeIdx}  className="border rounded-4 p-2 mb-2">
                                            <Input
                                                type="radio"
                                                name="mode"
                                                className="me-2"
                                                checked={tempSwitchState["Mode"] === mode}
                                                onChange={() => handleRadioChange("Mode", mode)}
                                            />
                                            {mode}
                                        </div>
                                    ))}
                                </div>
                                </Col>
                            )
                        ))
                    ) : offcanvasConfig?.name === "customerRetrieval" ? (
                        <Col lg={6} md={8}>
                        <div>
                            <LabelText displayText="Customer Retrieval" />
                            {offcanvasConfig?.value?.map((item, ind) => (
                                <div key={ind} className="border rounded-4 p-2 mb-2">
                                    <Input
                                        type="radio"
                                        name="customerRetrieval"
                                        className="me-2"
                                        checked={tempSwitchState[item.key]}
                                        onChange={() => CustomerChange(item.key)}
                                    />
                                    <LabelText displayText={item.key} />
                                </div>
                            ))}
                        </div>
                        </Col>
                    ):(offcanvasConfig?.value.length === 1 || offcanvasConfig?.name === "Prefixes" || offcanvasConfig?.name === "SequenceHandling")? (
                        <Col lg={12} md={12}>
                        {offcanvasConfig?.value.map((val)=>
                        <div>
                            <LabelText
                                displayText={
                                    configDisplayNameMapping[val?.key] || 
                                    configDisplayNameMapping[offcanvasConfig.name]
                                }
                                className="me-5"
                            />
                            {offcanvasConfig?.value.length !== 1 && 
                                <p className="text-muted mt-0">Enter A {offcanvasConfig.name === "Prefixes" ? `Prefix (Eg., ${val?.key.substr(0,3).toUpperCase()})` : "Sequence Number(Eg., 101, 1002, 1113)"} Up To {offcanvasConfig.name === "Prefixes" ? 16 : 8} Characters</p>
                            }
                            <Input
                                type={offcanvasConfig.name === "Prefixes" ? "text":"number"}
                                className="mb-3 mt-0"
                                value={tempSwitchState[val?.key]} 
                                onChange={(e) => handleInputChange(val?.key, e.target.value)}
                            />
                        </div>
                        )}
                        </Col>
                    ) : 
                        <div>
                        {(offcanvasConfig?.name === "notificationManagement" || offcanvasConfig?.name === "NotificationManagement" )&& 
                        (
                        <div>
                            <Input
                            type="checkbox"
                            className="me-2"
                            checked={tempSwitchState["Select All"] || false}
                            onChange={() => handleSelectAllChange()}
                            />
                            <LabelText displayText="Select All"/> </div>
                        )}
                        <div className={(offcanvasConfig?.name === "notificationManagement" || offcanvasConfig?.name === "NotificationManagement" )? "row" : ""}>
                        {offcanvasConfig?.value?.map((item, idx) => (
                            <Col md={8} lg={6}>
                            <div key={idx} className="border rounded-4 p-2 mb-2">
                                <Input
                                    type="checkbox"
                                    className="me-2"
                                    checked={tempSwitchState[item.key] || false}
                                    onChange={() => handleCheckboxChange(item.key)}
                                />
                                <LabelText displayText={notificationDisplayNameMapping[item.key] || item.key } />
                            </div>
                            </Col>
                        ))}
                        </div>
                        </div>    
                    }
            </Row>
        </Slider>
    );
}
